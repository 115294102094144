import service from '@/store/services/states-service';

const state = {
    list: [],
    cities: [],
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_CITIES: (state, cities) => {
        state.cities = cities;
    },
};

const actions = {
    list({commit, dispatch}, params) {
        return service.list()
            .then((list) => {
                commit('SET_LIST', list);
            });
    },
    cities({commit, dispatch}, params) {
        return service.cities(params)
            .then((cities) => {
                commit('SET_CITIES', cities);
            });
    },
};

const getters = {
    list: state => state.list,
    cities: state => state.cities,
};

const states = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default states;
