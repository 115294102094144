import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
// Profile
import UserProfile from "@/pages/Dashboard/Users/UserProfile.vue";

import MyProfile from "@/pages/Dashboard/Users/MyProfile.vue";

import UserCreate from "@/pages/Dashboard/Users/UserCreate.vue";

// User Management
import ListUserPage from "@/pages/Dashboard/Users/UserManagement/ListUserPage.vue";

//Schedules
import ScheduleCreate from "@/pages/Dashboard/Schedules/ScheduleCreate.vue";
import ListSchedulePage from "@/pages/Dashboard/Schedules/ListSchedulePage.vue";
import CrewScheduleCreate from "@/pages/Dashboard/Schedules/CrewScheduleCreate.vue";
import ListCrewSchedulePage from "@/pages/Dashboard/Schedules/ListCrewSchedulePage.vue";

//Clients
import ClientCreate from "@/pages/Dashboard/Clients/ClientCreate.vue";
import ListClientPage from "@/pages/Dashboard/Clients/ListClientPage.vue";
import ClientUpdate from "@/pages/Dashboard/Clients/ClientUpdate.vue";

//Crew Members
import CrewMemberCreate from "@/pages/Dashboard/CrewMembers/CrewMemberCreate.vue";
import ListCrewMemberPage from "@/pages/Dashboard/CrewMembers/ListCrewMemberPage.vue";
import CrewMemberUpdate from "@/pages/Dashboard/CrewMembers/CrewMemberUpdate.vue";

// Calendars
import SalesSchedulePage from "@/pages/Dashboard/Calendars/SalesSchedule.vue";
import ReRoofSchedulePage from "@/pages/Dashboard/Calendars/ReRoofSchedule.vue";
import RepairSchedulePage from "@/pages/Dashboard/Calendars/RepairSchedule.vue";

// Pages
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";

// Components pages
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";

// Maps pages
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";

//import middleware
import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import guest from "@/middleware/guest";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/notification",
  name: "Components",
  children: [
    {
      path: "table",
      name: "Table",
      components: { default: RegularTables },
      meta: { middleware: auth }
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
      meta: { middleware: auth }
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: { middleware: auth }
    },
    {
      path: "maps",
      name: "Maps",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
        middleware: auth
      },
      components: { default: FullScreenMap }
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: { middleware: auth }
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
        middleware: auth
      },
      components: { default: RtlSupport }
    }
  ]
};

let myProfile = {
    path: "/",
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
        {
            path: "my-profile",
            name: "My Profile",
            components: { default: MyProfile},
            meta: { middleware: auth }
        }
    ]
};



let usersMenu = {
  path: "/users",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: [auth, admin] }
    },
    {
      path: "user-create",
      name: "User Create",
      components: { default: UserCreate },
      meta: { middleware: [auth, admin] }
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: [auth, admin ]}
    }
  ]
};



let clientsMenu = {
    path: "/clients",
    component: DashboardLayout,
    name: "Clients",
    children: [
        {
            path: "client-update",
            name: "Client update",
            components: { default: ClientUpdate },
            meta: { middleware: [auth, admin] }
        },
        {
            path: "client-create",
            name: "Client Create",
            components: { default: ClientCreate },
            meta: { middleware: auth }
        },
        {
            path: "client-management/list-clients",
            name: "List Clients",
            components: { default: ListClientPage },
            meta: { middleware: [auth, admin ]}
        }
    ]
};



let crewMembersMenu = {
    path: "/crew-members",
    component: DashboardLayout,
    name: "Crew Members",
    children: [
        {
            path: "update",
            name: "Crew Member update",
            components: { default: CrewMemberUpdate },
            meta: { middleware: [auth, admin] }
        },
        {
            path: "create",
            name: "Crew Member Create",
            components: { default: CrewMemberCreate },
            meta: { middleware: auth }
        },
        {
            path: "management/list-crew-members",
            name: "List Crew Members",
            components: { default: ListCrewMemberPage },
            meta: { middleware: [auth, admin ]}
        }
    ]
};




let schedulesMenu = {
    path: "/schedules",
    component: DashboardLayout,
    name: "Schedules",
    children: [
        {
            path: "schedule-create",
            name: "Schedule Create",
            components: { default: ScheduleCreate },
            meta: { middleware: auth }
        },
        {
            path: "schedule-management/list-schedules",
            name: "List Schedules",
            components: { default: ListSchedulePage },
            meta: { middleware: [auth, admin ]}
        },
        {
            path: "crew-schedule-create",
            name: "Crew Schedule Create",
            components: { default: CrewScheduleCreate },
            meta: { middleware: auth }
        },
        {
            path: "crew-schedule-management/list-crew-schedules",
            name: "List Crew Schedules",
            components: { default: ListCrewSchedulePage },
            meta: { middleware: [auth, admin ]}
        }
    ]
};


let calendarsMenu = {
    path: "/calendars",
    component: DashboardLayout,
    name: "Calendars",
    children: [
        {
            path: "sales-schedule",
            name: "Sales Schedule",
            components: { default: SalesSchedulePage },
            meta: { middleware: auth }
        },
        {
            path: "re-roof-schedule",
            name: "Re-Roof Schedule",
            components: { default: ReRoofSchedulePage},
            meta: { middleware: auth }
        },
        {
            path: "repair-schedule",
            name: "Repair Schedule",
            components: { default: RepairSchedulePage },
            meta: { middleware: auth }
        }
    ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  {
    path: "/",
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { middleware: auth }
      }
    ]
  },
  componentsMenu,
  myProfile,
  usersMenu,
  clientsMenu,
  crewMembersMenu,
  schedulesMenu,
  calendarsMenu,
  authPages
];

export default routes;
