<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <form @submit.prevent="createCrew" >
            <md-card>
              <md-card-content class="form-body">
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>First Name</label>
                  <md-input v-model="crew.first_name"  maxlength="25" autocomplete="none"/>
                  <validation-error :errors="apiValidationErrors.first_name" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Last Name</label>
                  <md-input v-model="crew.last_name"  maxlength="25" autocomplete="none" />
                  <validation-error :errors="apiValidationErrors.last_name" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                            style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>State</label>
                  <md-select v-model="crew.state" style="padding-left: 10px" @md-selected="selectState" :disabled="us_states.length === 0" >
                    <template v-for="(us_state, index) in us_states">
                       <md-option :value="us_state"  :key="us_state + index">{{us_state}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                            style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>City</label>
                  <md-select v-model="crew.city" style="padding-left:10px" :disabled="cities.length === 0">
                    <template v-for="(city_for_project, index) in cities">
                      <md-option :value="city_for_project"  :key="city_for_project + index">{{city_for_project}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Color</label>
                  <md-input v-model="crew.color" type="color" maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.color" />
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button type="submit">
                  Create Crew Member
                </md-button>
              </md-card-actions>
            </md-card>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
  import { ValidationError } from "@/components";
  import formMixin from "@/mixins/form-mixin";

  export default {
    name: "CrewMemberCreate",
    components: { ValidationError},
    mixins: [formMixin],
    data: () => ({
        crew: {
            first_name: '',
            last_name: '',
            state: '',
            city: '',
            color: '#3B7024',
        },
        us_states: [],
        cities: [],
    }),

    methods: {

       async createCrew() {
           try {
               await this.$store.dispatch("crews/add", this.crew);
               await this.$store.dispatch(
                   "alerts/success",
                   "Successfully added."
               );
               await this.$router.push({name: 'List Crew Members'});

           } catch (e) {
               await this.$store.dispatch(
                   "alerts/error",
                   e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
               );
               this.setApiValidation(e.response.data.errors);
           }
      },

      async states() {
          await this.$store.dispatch("states/list", {});
          this.us_states = await this.$store.getters["states/list"];
      },

     async selectState(state) {
         this.cities = [];
         await this.$store.dispatch("states/cities", {state});
         this.cities = await this.$store.getters["states/cities"];
     },
    },
    mounted() {
       this.states();
    },
  }
</script>

<style scoped lang="scss">
  .form-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .md-field {
      width: 25%;
      &.textarea {
        width: 95% !important;
      }
    }
  }

</style>