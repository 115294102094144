import store from "../store";

export default function admin({ next, router }) {

  setTimeout(function () {
     let user =  store.getters["profile/me"];
     if(user && user.role === 'estimator') {
         return router.push({ name: "Home" });
     }
  }, 1000);

  return next();
}
