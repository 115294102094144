import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();



function createManager(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/managers/create`, params, options)
        .then(response => {
            return response.data
        });
}



function managers(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
  return axios.get(`${url}/managers`, options)
    .then(response => {
      return response.data
    });
}


function allManagers(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/managers/all`, options)
        .then(response => {
            return response.data
        });
}

function managersWithoutSchedule(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/managers/without/schedule`, options)
        .then(response => {
            return response.data
        });
}


function managersWithSchedule(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/managers/with/schedule`, options)
        .then(response => {
            return response.data
        });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  return axios.get(`${url}/users/${id}`, options)
    .then(response => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function all() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/all`, options)
        .then(response => {
            return response.data;
        });
}



function manager(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/manager/${id}`, options)
        .then(response => {
            let user = response.data;
            delete user.links;
            return user;
        });
}



function add(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/users`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/users/${user.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/manager/${id}`, options);
}

function upload(user, image) {
  const bodyFormData = new FormData();
  bodyFormData.append('attachment', image);

  return axios.post(`${url}/uploads/users/${user.id}/profile-image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

export default {
  managers,
  get,
  manager,
  add,
  update,
  destroy,
  upload,
  all,
  allManagers,
  createManager,
  managersWithoutSchedule,
  managersWithSchedule,
};

