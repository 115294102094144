<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <form @submit.prevent="createProfile">
          <signup-card>
            <md-card>
              <md-card-header class="md-card-header-icon">
                <div class="card-icon">
                  <md-icon>perm_identity</md-icon>
                </div>
                <h4 class="title">
                  Create Profile
                </h4>
              </md-card-header>

              <md-card-content>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon>face</md-icon>
                  <label>Name</label>
                  <md-input v-model="name" />
                  <validation-error :errors="apiValidationErrors.name" />
                </md-field>

                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon>email</md-icon>
                  <label>Email</label>
                  <md-input v-model="email" />
                  <validation-error :errors="apiValidationErrors.email" />
                </md-field>

                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Password</label>
                  <md-input v-model="password" type="password" />
                  <validation-error :errors="apiValidationErrors.password" />
                </md-field>

                <md-field class="md-form-group md-invalid">
                  <md-icon>lock_outline</md-icon>
                  <label>Confirm Password</label>
                  <md-input v-model="password_confirmation" type="password" />
                  <validation-error
                          :errors="apiValidationErrors.password_confirmation"
                  />
                </md-field>

                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <md-input v-model="color" type="color"/>
                  <validation-error :errors="apiValidationErrors.color" />
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button type="submit">
                  Create Profile
                </md-button>
              </md-card-actions>
            </md-card>
          </signup-card>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
  import { ValidationError } from "@/components";
  import formMixin from "@/mixins/form-mixin";
  export default {
    name: "user-create",
    components: { ValidationError },
    mixins: [formMixin],
    data: () => ({
        name: null,
        email: null,
        color: '#3B7024',
        password: null,
        password_confirmation: null,
        role: 'estimator'
    }),

    methods: {
      async createProfile() {

          const data = {
              name: this.name,
              email: this.email,
              password: this.password,
              password_confirmation: this.password_confirmation,
              color: this.color,
              role: this.role
          };

          try {
              await this.$store.dispatch("users/createManager", data);
              await this.$store.dispatch(
                  "alerts/success",
                  "Successfully added."
              );
              await this.$router.push({name: 'List Users'});

          } catch (e) {
              await this.$store.dispatch(
                  "alerts/error",
                  e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
              );
              this.setApiValidation(e.response.data.errors);
          }

      }
    }
  }
</script>