import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/states`, options)
        .then(response => {
            return response.data;
        });
}

function cities(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/states/${params.state}`,options)
        .then(response => {
            return response.data;
        });
}

export default {
    list,
    cities
};


