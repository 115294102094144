import service from '@/store/services/crews-service';

const state = {
  list: {},
  crew: {},
  crewsWithoutSchedule: {},
  crewsWithSchedule: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, crew) => {
    state.crew = crew;
  },
  SET_CREWS_WITHOUT_SCHEDULE: (state, crewsWithoutSchedule) => {
     state.crewsWithoutSchedule = crewsWithoutSchedule;
  },
  SET_CREWS_WITH_SCHEDULE: (state, crewsWithSchedule) => {
      state.crewsWithSchedule = crewsWithSchedule;
  },
};

const actions = {
    list({commit, dispatch}, params) {
        return service.list()
            .then((list) => {
                commit('SET_LIST', list);
            });
    },

    show({commit, dispatch}, params) {
        return service.show(params)
            .then((crew) => {
                commit('SET_RESOURCE', crew);
            });
    },

    add({commit, dispatch}, params) {
        return service.add(params)
            .then((crew) => {
                commit('SET_RESOURCE', crew);
            });
    },

    crewsWithoutSchedule({commit, dispatch}, params) {
        return service.crewsWithoutSchedule(params)
            .then((crews) => {
                commit('SET_CREWS_WITHOUT_SCHEDULE', crews);
            });
    },


    crewsWithSchedule({commit, dispatch}, params) {
        return service.crewsWithSchedule(params)
            .then((crews) => {
                commit('SET_CREWS_WITH_SCHEDULE', crews);
            });
    },

    update({commit, dispatch}, params) {
        return service.update(params)
            .then((crew) => {
                commit('SET_RESOURCE', crew);
            });
    },

    destroy({commit, dispatch}, params) {
        return service.destroy(params);
    }
};

const getters = {
  list: state => state.list,
  crew: state => state.crew,
  crewsWithoutSchedule: state => state.crewsWithoutSchedule,
  crewsWithSchedule: state => state.crewsWithSchedule,
};

const crews = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default crews;
