import service from '@/store/services/events-service';

const state = {
  list: {},
  event: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, event) => {
    state.event = event;
  },
};

const actions = {
    list({commit, dispatch}, params) {
        return service.list()
            .then((list) => {
                commit('SET_LIST', list);
            });
    },

    add({commit, dispatch}, params) {
        return service.add(params)
            .then((event) => {
                commit('SET_RESOURCE', event);
            });
    },

    update({commit, dispatch}, params) {
        return service.update(params)
            .then((event) => {
                commit('SET_RESOURCE', event);
            });
    },

    destroy({commit, dispatch}, params) {
        return service.destroy(params);
    }
};

const getters = {
  list: state => state.list,
  event: state => state.event,
};

const events = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default events;
