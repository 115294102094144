
<template>
    <div class='demo-app'>

        <div class='demo-app-sidebar'>
            <div class='demo-app-sidebar-section'>
                <h2>Instructions</h2>
                <ul>
                    <li>Select dates and you will be prompted to create a new event</li>
                    <li>Drag, drop, and resize events</li>
                    <li>Click an event to delete it</li>
                </ul>
            </div>
            <div class='demo-app-sidebar-section'>
                <label>
                    <input
                            type='checkbox'
                            :checked='calendarOptions.weekends'
                            @change='handleWeekendsToggle'
                    />
                    toggle weekends
                </label>
            </div>
            <div class='demo-app-sidebar-section'>
                <h2>All Events ({{ currentEvents.length }})</h2>
                <ul>
                    <li v-for='event in currentEvents' :key='event.id'>
                        <b>{{ event.startStr }}</b>
                        <i>{{ event.title }}</i>
                    </li>
                </ul>
            </div>
        </div>
        <div id="external-events">
            <div class="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2" v-for="(event, index) in crews"
                 :title="event.title"
                 :data-id="event.id"
                 :data-color="event.color"
                 :data-schedule-id="event.schedule_id"
                 :key="index"
                 :style="{
                    backgroundColor: event.color,
                    borderColor: event.color,
                    }"
            >
                <div class="fc-event-main">
                    <div>
                        <strong>{{event.title}}</strong>
                    </div>
                </div>
            </div>
        </div>


        <div class='demo-app-main'>
            <FullCalendar
                    id="mycalendar"
                    class='demo-app-calendar'
                    :options='calendarOptions'
            >
                <template v-slot:eventContent='arg'>
                    <b>{{ arg.timeText }}</b>
                    <i>{{ arg.event.title }}</i>
                </template>
            </FullCalendar>
        </div>
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import interactionPlugin, { Draggable }from '@fullcalendar/interaction'
    import { INITIAL_EVENTS, createEventId } from '../../../event-utils'

    export default {
        name: "re-roof-schedule",
        components: {
            FullCalendar,
        },

        data: function() {
            return {
                calendarOptions: {
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                    ],
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth'
                    },
                    initialView: 'dayGridMonth',
                    editable: true,
                    selectable: true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    weekends: true,
                    select: this.handleDateSelect,
                    eventClick: this.handleEventClick,
                    eventsSet: this.handleEvents,
                    events: [],
                    eventChange: this.handleDateChange,
                    droppable: true,
                    dragRevertDuration: 0,
                    drop: this.dropAction,
                    eventDragStop: this.eventDragStopAction,
                    eventReceive: this.handleEventReceive,
                },
                currentEvents: [],
                lastEvent: null,
                singleslot: '',
                crews: [],
            }
        },

        methods: {
            handleWeekendsToggle() {
                this.calendarOptions.weekends = !this.calendarOptions.weekends
            },


            handleEventReceive(eventInfo) {
            },

            async handleDateSelect(selectInfo) {
                // let title = prompt('Please enter a new title for your event');
                // let calendarApi = selectInfo.view.calendar;
                // calendarApi.unselect();
                // if (title) {
                //     let info =  {
                //         title: title,
                //         start: selectInfo.startStr,
                //         end: selectInfo.endStr,
                //         allDay: selectInfo.allDay
                //     };
                //     try {
                //         await this.$store.dispatch("events/add", info);
                //         this.lastEvent = await this.$store.getters["events/event"];
                //         this.calendarOptions.events.push(this.lastEvent);
                //     } catch (e) {
                //         await this.$store.dispatch(
                //             "alerts/error",
                //             "Oops, something went wrong!"
                //         );
                //     }
                // }
            },

            async handleEventClick(clickInfo) {
                try {
                    if (confirm(`Are you sure you want to delete the schedule '${clickInfo.event.title}'`)) {
                        await this.$store.dispatch("crew_schedules/destroy", clickInfo.event.id);
                        clickInfo.event.remove();
                        await this.$store.dispatch(
                            "alerts/success",
                            "Schedule deleted successfully."
                        );
                        this.getList();
                    }
                } catch (e) {
                    await this.$store.dispatch(
                        "alerts/error",
                        e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
                    );
                }
            },

            handleEvents(events) {
                this.currentEvents = events
            },

            async handleDateChange(selectInfo) {
                let info =  {
                    title: selectInfo.event.title,
                    start: selectInfo.event.startStr,
                    end: selectInfo.event.endStr ? selectInfo.event.endStr :  selectInfo.event.startStr,
                    allDay: selectInfo.event.allDay,
                    schedule_id: selectInfo.event.id
                };
                try {
                    await this.$store.dispatch("crew_schedules/updateForSchedule", info);
                    this.getList();
                } catch (e) {
                    await this.$store.dispatch(
                        "alerts/error",
                        e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
                    );
                }
            },

            async getList() {
                await this.$store.dispatch("crews/crewsWithSchedule");
                let data  = await this.$store.getters["crews/crewsWithSchedule"];
                this.crews  = [];
                this.calendarOptions.events = [];
                data.forEach(item=> {
                    this.crews.push({
                        id: item.id,
                        title: item.first_name +' '+ item.last_name,
                        color: item.color,
                        schedule_id: item.schedule.id
                    });
                    this.calendarOptions.events.push({
                        id: item.schedule.id,
                        allDay: item.schedule.allDay,
                        color: item.color,
                        end: item.schedule.end,
                        start: item.schedule.start,
                        title:  item.first_name +' '+ item.last_name,
                    })
                });

            },

            async dropAction(eventInfo) {
                let updateSchedule =  {
                    id: eventInfo.draggedEl.getAttribute("data-id"),
                    title: eventInfo.draggedEl.getAttribute("title"),
                    start: eventInfo.dateStr,
                    end: eventInfo.dateStr,
                    allDay: eventInfo.allDay,
                    color: eventInfo.draggedEl.getAttribute("data-color"),
                    schedule_id: eventInfo.draggedEl.getAttribute("data-schedule-id")
                };

                try {
                    await this.$store.dispatch("crew_schedules/updateForSchedule", updateSchedule);
                    this.getList();
                } catch (e) {
                    await this.$store.dispatch(
                        "alerts/error",
                        e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
                    );
                }
            },


           crewsGet() {
                this.singleslot = document.getElementById("external-events");
                new Draggable(this.singleslot, {
                    itemSelector: '.fc-event',
                    eventData: function(eventEl) {
                        let id = eventEl.dataset.id;
                        let title = eventEl.getAttribute("title");
                        let color = eventEl.dataset.color;
                        let custom = eventEl.dataset.custom;
                        return {
                            id: id,
                            title: title,
                            color: color,
                            custom: custom,
                            create: true
                        };
                    }
                });
            },



            eventDragStopAction(event, jsEvent, ui, view ) {
                // console.log(event)
                // console.log(event, jsEvent, ui, view )
            }
        },


        mounted() {
            this.getList();
            this.crewsGet()
        },

    }
</script>

<style lang='css' scoped>

    #external-events{
        margin-left: 50px;
    }

    .fc-event {
        padding: 8px;
        margin-top: 5px;
        cursor: pointer;
    }

    h2 {
        margin: 0;
        font-size: 16px;
    }

    ul {
        margin: 0;
        padding: 0 0 0 1.5em;
    }

    li {
        margin: 1.5em 0;
        padding: 0;
    }

    b { /* used for event dates/times */
        margin-right: 3px;
    }

    .demo-app {
        display: flex;
        min-height: 100%;
        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
        font-size: 14px;
    }

    .demo-app-sidebar {
        width: 300px;
        line-height: 1.5;
        background: #eaf9ff;
        border-right: 1px solid #d3e2e8;
    }

    .demo-app-sidebar-section {
        padding: 2em;
    }

    .demo-app-main {
        flex-grow: 1;
        padding: 3em;
    }

    .fc { /* the calendar root */
        max-width: 1100px;
        margin: 0 auto;
    }

</style>