import service from '@/store/services/schedules-service';

const state = {
  list: {},
  schedule: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, schedule) => {
    state.schedule = schedule;
  },
};

const actions = {
    list({commit, dispatch}, params) {
        return service.list()
            .then((list) => {
                commit('SET_LIST', list);
            });
    },

    create({commit, dispatch}, params) {
        return service.create(params)
            .then((event) => {
                commit('SET_RESOURCE', event);
            });
    },

    updateForSchedule({commit, dispatch}, params) {
        return service.updateForSchedule(params)
            .then((event) => {
                commit('SET_RESOURCE', event);
            });
    },

    destroy({commit, dispatch}, params) {
        return service.destroy(params);
    }
};

const getters = {
  list: state => state.list,
  schedule: state => state.schedule,
};

const schedules = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default schedules;
