import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/events`, options)
        .then(response => {
            return response.data.events;
        });
}


function add(event) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/events`,event, options)
        .then(response => {
            return response.data;
        });
}

function update(event) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.patch(`${url}/events/${event.id}`, event, options)
        .then(response => {
            return response.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.delete(`${url}/events/${id}`, options);
}


export default {
    list,
    add,
    update,
    destroy,
};


