<template>
  <div v-if="user" class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <user-edit-card :user="user"  :type="type" />
      </div>
      <div class="md-layout-item md-size-100">
        <user-password-card :user="user"/>
      </div>
    </div>
  </div>
</template>

<script>
  import UserEditCard from "@/pages/Dashboard/Users/UserProfile/EditProfileCard.vue";
  import UserPasswordCard from "@/pages/Dashboard/Users/UserProfile/EditPasswordCard.vue";
  import router from "@/router";
  export default {
    name: "user-profile",

    components: {
      "user-edit-card": UserEditCard,
      "user-password-card": UserPasswordCard
    },

    data: () => ({
      user: null,
      type: 'userProfile',
    }),

    created() {
      this.getProfile();
    },

    methods: {
      async getProfile() {
        if (this.$route.query.id === undefined) {
            router.push({path: '/dashboard'});
        }
        await this.$store.dispatch("users/manager", this.$route.query.id);
        this.user = await this.$store.getters["users/manager"];
      }
    }
  }
</script>