import service from '@/store/services/clients-service';

const state = {
  list: {},
  client: {},
  listsWithoutSchedule: {},
  listsWithoutCrewSchedule: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, client) => {
    state.client = client;
  },

  SET_LIST_WITHOUT_SCHEDULE: (state, listsWithoutSchedule) => {
     state.listsWithoutSchedule= listsWithoutSchedule;
  },

  SET_LIST_WITHOUT_CREW_SCHEDULE: (state, listsWithoutCrewSchedule) => {
     state.listsWithoutCrewSchedule= listsWithoutCrewSchedule;
  },
};

const actions = {
    list({commit, dispatch}, params) {
        return service.list()
            .then((list) => {
                commit('SET_LIST', list);
            });
    },
    listsWithoutSchedule({commit, dispatch}, params) {
        return service.listsWithoutSchedule()
            .then((list) => {
                commit('SET_LIST_WITHOUT_SCHEDULE', list);
            });
    },


    listsWithoutCrewSchedule({commit, dispatch}, params) {
        return service.listsWithoutCrewSchedule()
            .then((list) => {
                commit('SET_LIST_WITHOUT_CREW_SCHEDULE', list);
            });
    },

    show({commit, dispatch}, params) {
        return service.show(params)
            .then((client) => {
                commit('SET_RESOURCE', client);
            });
    },

    add({commit, dispatch}, params) {
        return service.add(params)
            .then((client) => {
                commit('SET_RESOURCE', client);
            });
    },

    update({commit, dispatch}, params) {
        return service.update(params)
            .then((client) => {
                commit('SET_RESOURCE', client);
            });
    },

    destroy({commit, dispatch}, params) {
        return service.destroy(params);
    }
};

const getters = {
  list: state => state.list,
  client: state => state.client,
  listsWithoutSchedule: state => state.listsWithoutSchedule,
  listsWithoutCrewSchedule: state => state.listsWithoutCrewSchedule,
};

const clients = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default clients;
