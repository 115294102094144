<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <form @submit.prevent="createSchedule">
            <md-card>
              <md-card-content class="form-body">
                <md-field  class="md-form-group md-invalid"
                            style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Crews</label>
                  <md-select v-model="schedule.crew_id" style="padding-left: 10px"  :disabled="crews.length === 0" >
                    <template v-for="(crewData, index) in  crews">
                       <md-option :value="crewData.id"  :key="index">{{crewData.first_name +' '+ crewData.last_name}}</md-option>
                    </template>
                  </md-select>
                  <validation-error :errors="apiValidationErrors.crew_id" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Projects</label>
                  <md-select v-model="schedule.client_id" style="padding-left: 10px" :disabled="projects.length === 0" >
                    <template v-for="(project, index) in projects">
                      <md-option :value="project.id"  :key="index">{{project.title}}</md-option>
                    </template>
                  </md-select>
                  <validation-error :errors="apiValidationErrors.client_id" />
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button type="submit">
                  Create Crew Schedule
                </md-button>
              </md-card-actions>
            </md-card>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
  import { ValidationError } from "@/components";
  import formMixin from "@/mixins/form-mixin";

  export default {
    name: "CrewScheduleCreate",
    components: { ValidationError},
    mixins: [formMixin],
    data: () => ({
        schedule: {
            crew_id: null,
            client_id: null,
        },
        crews: [],
        projects: [],
    }),

    methods: {
        async getCrewMembers() {
            await this.$store.dispatch("crews/crewsWithoutSchedule");
            this.crews = await this.$store.getters["crews/crewsWithoutSchedule"];
        },

        async getProjects() {
            await this.$store.dispatch("clients/listsWithoutCrewSchedule");
            this.projects = await this.$store.getters["clients/listsWithoutCrewSchedule"];
        },

       async createSchedule() {
           try {
               await this.$store.dispatch("crew_schedules/create", this.schedule);
               await this.$store.dispatch(
                   "alerts/success",
                   "Successfully added."
               );
               await this.$router.push({name: 'List Crew Schedules'});

           } catch (e) {
               await this.$store.dispatch(
                   "alerts/error",
                   e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
               );
               this.setApiValidation(e.response.data.errors);
           }
      },

    },
    mounted() {
        this.getCrewMembers();
        this.getProjects();
    },

  }
</script>

<style scoped lang="scss">
  .form-body {
    display: flex;

    .md-field {
      width: 25%;
    }
  }

</style>