import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/crews`, options)
        .then(response => {
            return response.data
        });
}


function crewsWithoutSchedule(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/crews/without/crew-schedule`, options)
        .then(response => {
            return response.data
        });
}


function crewsWithSchedule(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/crews/with/crew-schedule`, options)
        .then(response => {
            return response.data
        });
}


function show(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/crews/show/${id}`, options)
        .then(response => {
            return response.data
        });
}

function add(client) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/crews`,client, options)
        .then(response => {
            return response.data;
        });
}

function update(client) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/crews/update`, client, options)
        .then(response => {
            return response.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.delete(`${url}/crews/${id}`, options);
}


export default {
    list,
    add,
    update,
    destroy,
    show,
    crewsWithoutSchedule,
    crewsWithSchedule,
};


