import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/crew-schedules`, options)
        .then(response => {
            return response.data;
        });
}


function create(schedule) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/crew-schedules`,schedule, options)
        .then(response => {
            return response.data;
        });
}

function updateForSchedule(schedule) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/crew-schedules/update/for-calendar`, schedule, options)
        .then(response => {
            return response.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.delete(`${url}/crew-schedules/${id}`, options);
}


export default {
    list,
    create,
    updateForSchedule,
    destroy,
};


