var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),(_vm.role !== 'estimator')?_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Users', icon: 'library_books'}}},[_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            path: '/users/user-management/list-users',
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: 'Clients', icon: 'library_books' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Client Management',
            path: '/clients/client-management/list-clients',
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Crew Members', icon: 'library_books' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Crew Members Management',
            path: '/crew-members/management/list-crew-members',
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Schedules', icon: 'library_books'}}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Schedule Management',
            path: '/schedules/schedule-management/list-schedules',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Crew Schedule Management',
            path: '/schedules/crew-schedule-management/list-crew-schedules',
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Calendars', icon: 'library_books' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Sales Schedule',
            path: '/calendars/sales-schedule',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Re-Roof Schedule',
            path: '/calendars/re-roof-schedule',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Repair Schedule',
            path: '/calendars/repair-schedule',
          }}})],1)],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('fixed-plugin',{attrs:{"color":_vm.sidebarBackground,"colorBg":_vm.sidebarBackgroundColor,"sidebarMini":_vm.sidebarMini,"sidebarImg":_vm.sidebarImg,"image":_vm.sidebarBackgroundImage},on:{"update:color":function($event){_vm.sidebarBackground=$event},"update:colorBg":function($event){_vm.sidebarBackgroundColor=$event},"update:color-bg":function($event){_vm.sidebarBackgroundColor=$event},"update:sidebarMini":function($event){_vm.sidebarMini=$event},"update:sidebar-mini":function($event){_vm.sidebarMini=$event},"update:sidebarImg":function($event){_vm.sidebarImg=$event},"update:sidebar-img":function($event){_vm.sidebarImg=$event},"update:image":function($event){_vm.sidebarBackgroundImage=$event}}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }