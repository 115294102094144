<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <form @submit.prevent="createclient">
            <md-card>
              <md-card-content class="form-body">
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label style="margin-left: 200px">Client File</label>
                  <md-input type="file" v-on:change="onFileChange"/>
                  <validation-error :errors="apiValidationErrors.client_file" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Status</label>
                  <md-select v-model="client.status" style="padding-left: 10px" >
                    <template v-for="(same, index) in ['Open', 'Contract']">
                      <md-option :value="same"  :key="same + index">{{same}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Referred By</label>
                  <md-select v-model="client.referred_by" style="padding-left: 10px">
                    <md-option value="Other">By a friend</md-option>
                    <md-option value="Other">Various advertisements</md-option>
                    <md-option value="Other">Driving by a project</md-option>
                    <md-option value="Other">Other</md-option>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Title</label>
                  <md-input v-model="client.title"  maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.title" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>First Name</label>
                  <md-input v-model="client.first_name"  maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.first_name" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Last Name</label>
                  <md-input v-model="client.last_name"  maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.last_name" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Title2</label>
                  <md-input v-model="client.title2"  maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.title2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>First Name2</label>
                  <md-input v-model="client.first_name2"  maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.first_name2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Last Name2</label>
                  <md-input v-model="client.last_name2"  maxlength="25" />
                  <validation-error :errors="apiValidationErrors.last_name2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Company</label>
                  <md-input v-model="client.company"  maxlength="50"/>
                  <validation-error :errors="apiValidationErrors.company" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                            style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Project State</label>
                  <md-select v-model="client.project_state" style="padding-left: 10px" @md-selected="selectProjectState" :disabled="us_states.length === 0" >
                    <template v-for="(us_state, index) in us_states">
                       <md-option :value="us_state"  :key="us_state + index">{{us_state}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                            style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Project City</label>
                  <md-select v-model="client.project_city" style="padding-left:10px" :disabled="cities_for_project.length === 0">
                    <template v-for="(city_for_project, index) in cities_for_project">
                      <md-option :value="city_for_project"  :key="city_for_project + index">{{city_for_project}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Project Street</label>
                  <md-input v-model="client.project_street"  maxlength="50" />
                  <validation-error :errors="apiValidationErrors.project_street" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Project Street2</label>
                  <md-input v-model="client.project_street2"  maxlength="50" />
                  <validation-error :errors="apiValidationErrors.project_street2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Project Zip</label>
                  <md-input v-model="client.project_zip"  maxlength="10" />
                  <validation-error :errors="apiValidationErrors.project_zip" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Bill2 Same</label>
                  <md-select v-model="client.bill2_same" style="padding-left: 10px" >
                    <template v-for="(same, index) in ['Yes', 'No']">
                      <md-option :value="same"  :key="same + index">{{same}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Bill State</label>
                  <md-select v-model="client.bill_state" style="padding-left: 10px" @md-selected="selectBillState" :disabled="us_states.length === 0" >
                    <template v-for="(us_state, index) in us_states">
                      <md-option :value="us_state"  :key="us_state + index">{{us_state}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Bill City</label>
                  <md-select v-model="client.bill_city" style="padding-left:10px" :disabled="cities_for_bill.length === 0">
                    <template v-for="(city_for_bill, index) in cities_for_bill">
                      <md-option :value="city_for_bill"  :key="city_for_bill + index">{{city_for_bill}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Bill Street</label>
                  <md-input v-model="client.bill_street"  maxlength="50"/>
                  <validation-error :errors="apiValidationErrors.bill_street" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Bill Street2</label>
                  <md-input v-model="client.bill_street2"  maxlength="50"/>
                  <validation-error :errors="apiValidationErrors.bill_street2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Bill Zip</label>
                  <md-input v-model="client.bill_zip"   maxlength="10"/>
                  <validation-error :errors="apiValidationErrors.bill_zip" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Market Code</label>
                  <md-input v-model="client.market_code" maxlength="50" />
                  <validation-error :errors="apiValidationErrors.market_code" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Old Roof</label>
                  <md-input v-model="client.old_roof" maxlength="50" />
                  <validation-error :errors="apiValidationErrors.old_roof" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Home Phone</label>
                  <md-input v-model="client.home_phone" type="number" maxlength="20"/>
                  <validation-error :errors="apiValidationErrors.home_phone" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Work Phone</label>
                  <md-input v-model="client.work_phone" type="number" maxlength="20"/>
                  <validation-error :errors="apiValidationErrors.work_phone" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Mobile Phone</label>
                  <md-input v-model="client.mobile_phone"  type="number" maxlength="20"/>
                  <validation-error :errors="apiValidationErrors.mobile_phone" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Fax</label>
                  <md-input v-model="client.fax"  maxlength="20"/>
                  <validation-error :errors="apiValidationErrors.fax" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Email</label>
                  <md-input v-model="client.email" type="email" maxlength="75"/>
                  <validation-error :errors="apiValidationErrors.email" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Email2</label>
                  <md-input v-model="client.email2" type="email" maxlength="75"/>
                  <validation-error :errors="apiValidationErrors.email2" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Company List</label>
                  <md-input v-model="client.company_list" maxlength="50" />
                  <validation-error :errors="apiValidationErrors.company_list" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Selection</label>
                  <md-input v-model="client.selection" maxlength="25" />
                  <validation-error :errors="apiValidationErrors.selection" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Trim Clr</label>
                  <md-input v-model="client.trim_clr" maxlength="15" />
                  <validation-error :errors="apiValidationErrors.trim_clr" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Job Cost</label>
                  <md-input v-model="client.job_cost"  maxlength="50"/>
                  <validation-error :errors="apiValidationErrors.job_cost" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label style="left: 50%">Appt Date And Time</label>
                  <md-input v-model="client.appt_date_time"  type="datetime-local"/>
                  <validation-error :errors="apiValidationErrors.appt_date_time" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Week Scheduled</label>
                  <md-input v-model="client.week_scheduled" maxlength="25" />
                  <validation-error :errors="apiValidationErrors.week_scheduled"  />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Four Star</label>
                  <md-select v-model="client.four_star" style="padding-left: 10px" >
                    <template v-for="(four, index) in ['Yes', 'No']">
                      <md-option :value="four"  :key="four + index">{{four}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Five Star</label>
                  <md-select v-model="client.five_star" style="padding-left: 10px" >
                    <template v-for="(five, index) in ['Yes', 'No']">
                      <md-option :value="five"  :key="five + index">{{five}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Oc Warranty</label>
                  <md-select v-model="client.oc_warranty" style="padding-left: 10px" >
                    <template v-for="(warranty, index) in ['Yes', 'No']">
                      <md-option :value="warranty"  :key="warranty + index">{{warranty}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Sales Ta Amt</label>
                  <md-input v-model="client.sales_ta_amt"  step="any" type="number" maxlength="15" />
                  <validation-error :errors="apiValidationErrors.sales_ta_amt" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Location Code</label>
                  <md-input v-model="client.location_code" maxlength="50" />
                  <validation-error :errors="apiValidationErrors.location_code" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Location Name</label>
                  <md-input v-model="client.location_name" maxlength="50" />
                  <validation-error :errors="apiValidationErrors.location_name" />
                </md-field>
                <md-field  class="md-form-group md-invalid"
                           style="margin-bottom: 2rem;">
                  <md-icon></md-icon>
                  <label>Deactivate</label>
                  <md-select v-model="client.deactivate" style="padding-left: 10px" >
                    <template v-for="(deactivate, index) in ['Yes', 'No']">
                      <md-option :value="deactivate"  :key="deactivate + index">{{deactivate}}</md-option>
                    </template>
                  </md-select>
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Color Selection</label>
                  <md-input v-model="client.color_selection" type="color" maxlength="25" />
                  <validation-error :errors="apiValidationErrors.color_selection" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Color Code</label>
                  <md-input v-model="client.color_code" type="color" maxlength="25"/>
                  <validation-error :errors="apiValidationErrors.color_code" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label>Co</label>
                  <md-input v-model="client.co" maxlength="15"/>
                  <validation-error :errors="apiValidationErrors.co" />
                </md-field>
                <md-field
                        class="md-form-group md-invalid textarea"
                        style="margin-bottom: 2rem"
                >
                  <md-icon></md-icon>
                  <label style="padding-left: 2rem">Notes</label>
                  <md-textarea v-model="client.notes" style="padding-left: 25px" maxlength="500"></md-textarea>
                  <validation-error :errors="apiValidationErrors.notes" />
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button type="submit">
                  Create Client
                </md-button>
              </md-card-actions>
            </md-card>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
  import { ValidationError } from "@/components";
  import formMixin from "@/mixins/form-mixin";

  export default {
    name: "client-create",
    components: { ValidationError},
    mixins: [formMixin],
    data: () => ({
        client: {
            client_file: '',
            first_name: '',
            last_name: '',
            title: '',
            title2: '',
            status : 'Open',
            first_name2: '',
            last_name2: '',
            company: '',
            project_state: '',
            project_city: '',
            project_street: '',
            project_street2: '',
            project_zip: '',
            bill2_same: 'No',
            bill_state: '',
            bill_city: '',
            bill_street: '',
            bill_street2: '',
            bill_zip: '',
            market_code: '',
            referred_by: '',
            old_roof: '',
            home_phone: '',
            work_phone: '',
            mobile_phone: '',
            fax: '',
            email: '',
            email2: '',
            notes: '',
            company_list: '',
            selection: '',
            color_selection: '#2a33ff',
            trim_clr: '',
            job_cost: '',
            week_scheduled: '',
            appt_date_time: '',
            four_star: 'No',
            five_star: 'No',
            oc_warranty: 'No',
            sales_ta_amt: '',
            location_code: '',
            location_name: '',
            deactivate: 'No',
            color_code: '#3B7024',
            co: '',
        },
        us_states: [],
        cities_for_project: [],
        cities_for_bill: [],
    }),

    methods: {
        onFileChange(e){
            this.client.client_file = e.target.files[0];
        },

       async createclient() {
           let formData = new FormData();
           formData.append('client_file',  this.client.client_file);
           formData.append('title',  this.client.title);
           formData.append('first_name',  this.client.first_name);
           formData.append('last_name',  this.client.last_name);
           formData.append('status',  this.client.status);
           formData.append('title2',  this.client.title2);
           formData.append('first_name2',  this.client.first_name2);
           formData.append('last_name2',  this.client.last_name2);
           formData.append('company',  this.client.company);
           formData.append('project_state',  this.client.project_state);
           formData.append('project_city',  this.client.project_city);
           formData.append('project_street',  this.client.project_street);
           formData.append('project_street2',  this.client.project_street2);
           formData.append('project_zip',  this.client.project_zip);
           formData.append('bill2_same',  this.client.bill2_same);
           formData.append('bill_state',  this.client.bill_state);
           formData.append('bill_city',  this.client.bill_city);
           formData.append('bill_street',  this.client.bill_street);
           formData.append('bill_street2',  this.client.bill_street2);
           formData.append('bill_zip',  this.client.bill_zip);
           formData.append('market_code',  this.client.market_code);
           formData.append('referred_by',  this.client.referred_by);
           formData.append('old_roof',  this.client.old_roof);
           formData.append('home_phone',  this.client.home_phone);
           formData.append('work_phone',  this.client.work_phone);
           formData.append('mobile_phone',  this.client.mobile_phone);
           formData.append('fax',  this.client.fax);
           formData.append('email',  this.client.email);
           formData.append('email2',  this.client.email2);
           formData.append('notes',  this.client.notes);
           formData.append('company_list',  this.client.company_list);
           formData.append('selection',  this.client.selection);
           formData.append('color_selection',  this.client.color_selection);
           formData.append('trim_clr',  this.client.trim_clr);
           formData.append('job_cost',  this.client.job_cost);
           formData.append('week_scheduled',  this.client.week_scheduled);
           formData.append('appt_date_time',  this.client.appt_date_time);
           formData.append('four_star',  this.client.four_star);
           formData.append('five_star',  this.client.five_star);
           formData.append('oc_warranty',  this.client.oc_warranty);
           formData.append('sales_ta_amt',  this.client.sales_ta_amt ? this.client.sales_ta_amt : 0);
           formData.append('location_code',  this.client.location_code);
           formData.append('location_name',  this.client.location_name);
           formData.append('deactivate',  this.client.deactivate);
           formData.append('color_code',  this.client.color_code);
           formData.append('co',  this.client.co);
           try {
               await this.$store.dispatch("clients/add", formData);
               await this.$store.dispatch(
                   "alerts/success",
                   "Successfully added."
               );
               await this.$router.push({name: 'List Clients'});

           } catch (e) {
               await this.$store.dispatch(
                   "alerts/error",
                   e.response.data.errors ? e.response.data.errors[0].detail : "Oops, something went wrong!",
               );
               this.setApiValidation(e.response.data.errors);
           }
      },

      async states() {
          await this.$store.dispatch("states/list", {});
          this.us_states = await this.$store.getters["states/list"];
      },

     async selectProjectState(state) {
         this.cities_for_project = [];
         await this.$store.dispatch("states/cities", {state});
         this.cities_for_project = await this.$store.getters["states/cities"];
     },
     async selectBillState(state) {
         this.cities_for_bill = [];
         await this.$store.dispatch("states/cities", {state});
         this.cities_for_bill = await this.$store.getters["states/cities"];
     }
    },
    mounted() {
       this.states();
    },
  }
</script>

<style scoped lang="scss">
  .form-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .md-field {
      width: 25%;
      &.textarea {
        width: 95% !important;
      }
    }
  }

</style>