<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Crew Members List</h4>
        </md-card-header>
        <md-card-content>
          <div class="text-right">
            <md-button class="md-primary md-dense" @click="addCrewMember()">
              Add Crew Member
            </md-button>
          </div>
          <md-table
            :value="table"
            :md-sort.sync="sortation.field"
            :md-sort-order.sync="sortation.order"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label>Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="First Name" md-sort-by="first_name">
                {{item.first_name }}
              </md-table-cell>
              <md-table-cell md-label="Last Name" md-sort-by="last_name">
                {{item.last_name }}
              </md-table-cell>
              <md-table-cell md-label="State" md-sort-by="state">
                {{ item.state }}
              </md-table-cell>
              <md-table-cell md-label="City" md-sort-by="city">
                {{ item.city }}
              </md-table-cell>
              <md-table-cell md-label="Color" md-sort-by="color">
                <input type="color" :value="item.color" disabled>
              </md-table-cell>
              <md-table-cell md-label="Created At" md-sort-by="created_at">
                {{ getDate(item.created_at) }}
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="linkCrew(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-icon-button md-raised md-round md-danger"
                  @click="deleteCrew(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moments from "moment";
import router from "@/router";

export default {
  name: "CrewMemberPage",
  components: {
    pagination: Pagination,
  },

  data: () => ({
    table: [],

    query: null,

    sortation: {
      field: "created_at",
      order: "asc",
    },

    pagination: {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: 1,
  }),

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    this.getList();
  },

  methods: {
   async getList() {
       await this.$store.dispatch("crews/list");
       this.table = await this.$store.getters["crews/list"];
    },

    linkCrew(id) {
        router.push({path: '/crew-members/update?id=' + id});
    },

   async deleteCrew(id){
       try {
           await this.$store.dispatch("crews/destroy", id);
           this.getList();
       } catch (e) {
           await this.$store.dispatch(
               "alerts/error",
               "Oops, something went wrong!"
           );
       }
    },

    addCrewMember(){
        router.push({path: '/crew-members/create'});
    },

    customSort() {
      return false;
    },

    getDate(date) {
        return moments(date).format("dddd, MMMM Do YYYY, h:mm:ss a")
    }
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}
</style>
