import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/projects`, options)
        .then(response => {
            return response.data
        });
}



function listsWithoutSchedule() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/projects/without/schedule`, options)
        .then(response => {
            return response.data
        });
}


function listsWithoutCrewSchedule() {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/projects/without/crew-schedule`, options)
        .then(response => {
            return response.data
        });
}

function show(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.get(`${url}/projects/show/${id}`, options)
        .then(response => {
            return response.data
        });
}

function add(client) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/projects`,client, options)
        .then(response => {
            return response.data;
        });
}

function update(client) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/projects/update`, client, options)
        .then(response => {
            return response.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.delete(`${url}/projects/${id}`, options);
}


export default {
    list,
    add,
    update,
    destroy,
    show,
    listsWithoutSchedule,
    listsWithoutCrewSchedule,
};


