<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
                :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
        />

        <sidebar-item opened :link="{ name: 'Users', icon: 'library_books'}" v-if="role !== 'estimator'">
          <sidebar-item
            :link="{
              name: 'User Management',
              path: '/users/user-management/list-users',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Clients', icon: 'library_books' }">
          <sidebar-item
                  :link="{
              name: 'Client Management',
              path: '/clients/client-management/list-clients',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Crew Members', icon: 'library_books' }">
          <sidebar-item
                  :link="{
              name: 'Crew Members Management',
              path: '/crew-members/management/list-crew-members',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Schedules', icon: 'library_books'}">
          <sidebar-item
                  :link="{
              name: 'Schedule Management',
              path: '/schedules/schedule-management/list-schedules',
            }"
          />
          <sidebar-item
                  :link="{
              name: 'Crew Schedule Management',
              path: '/schedules/crew-schedule-management/list-crew-schedules',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Calendars', icon: 'library_books' }">
          <sidebar-item
             :link="{
              name: 'Sales Schedule',
              path: '/calendars/sales-schedule',
            }"
          />
          <sidebar-item
              :link="{
              name: 'Re-Roof Schedule',
              path: '/calendars/re-roof-schedule',
            }"
          />
          <sidebar-item
              :link="{
              name: 'Repair Schedule',
              path: '/calendars/repair-schedule',
            }"
          />
        </sidebar-item>

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{-->
            <!--name: 'الجداول',-->
            <!--icon: 'content_paste',-->
            <!--path: '/components/table',-->
          <!--}"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--:link="{-->
            <!--name: 'Table Lists',-->
            <!--icon: 'content_paste',-->
            <!--path: '/components/table',-->
          <!--}"-->
        <!--/>-->

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{-->
            <!--name: 'طباعة',-->
            <!--icon: 'library_books',-->
            <!--path: '/components/typography',-->
          <!--}"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--v-else-->
          <!--:link="{-->
            <!--name: 'Typography',-->
            <!--icon: 'library_books',-->
            <!--path: '/components/typography',-->
          <!--}"-->
        <!--/>-->

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{-->
            <!--name: 'الرموز',-->
            <!--icon: 'bubble_chart',-->
            <!--path: '/components/icons',-->
          <!--}"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--v-else-->
          <!--:link="{-->
            <!--name: 'Icons',-->
            <!--icon: 'bubble_chart',-->
            <!--path: '/components/icons',-->
          <!--}"-->
        <!--/>-->

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{-->
            <!--name: 'خرائط جوجل',-->
            <!--icon: 'place',-->
            <!--path: '/components/maps',-->
          <!--}"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--v-else-->
          <!--:link="{ name: 'Maps', icon: 'place', path: '/components/maps' }"-->
        <!--/>-->

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{-->
            <!--name: 'إخطارات',-->
            <!--icon: 'notifications',-->
            <!--path: '/components/notifications',-->
          <!--}"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--v-else-->
          <!--:link="{-->
            <!--name: 'Notifications',-->
            <!--icon: 'notifications',-->
            <!--path: '/components/notifications',-->
          <!--}"-->
        <!--/>-->

        <!--<sidebar-item-->
          <!--v-if="$route.meta.rtlActive"-->
          <!--:link="{ name: 'دعم رتل', icon: 'language', path: '/components/rtl' }"-->
        <!--/>-->
        <!--<sidebar-item-->
          <!--v-else-->
          <!--:link="{-->
            <!--name: 'RTL Support',-->
            <!--icon: 'language',-->
            <!--path: '/components/rtl',-->
          <!--}"-->
        <!--/>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import FixedPlugin from "../../FixedPlugin.vue";
import UserMenu from "./Extra/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    FixedPlugin,
    MobileMenu,
    UserMenu,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage:
        process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      role: null,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

  },
  updated() {
    reinitScrollbar();
  },

  async created() {
        this.$store.watch(() => this.$store.getters["profile/me"], (me) => {
            this.role= me.role
        });
        await this.$store.dispatch("profile/me");
    },

  mounted() {
    reinitScrollbar();

  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
