import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import clients from "./modules/clients-module";
import crews from "./modules/crews-module";
import events from "./modules/events-module";
import states from "./modules/states-module";
import schedules from "./modules/schedules-module";
import crew_schedules from "./modules/crew-schedules-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    clients,
    crews,
    events,
    states,
    schedules,
    crew_schedules,
  }
});
