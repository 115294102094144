import service from '@/store/services/users-service';

const state = {
  list: {},
  user: {},
  all: {},
  managers: {},
  manager: {},
  allManagers: {},
  managersWithoutSchedule: {},
  managersWithSchedule: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, user) => {
    state.user = user;
  },

  SET_ALL: (state, all) => {
    state.all = all;
  },

  SET_MANAGERS: (state, managers) => {
    state.managers = managers;
  },

  SET_MANAGER: (state, manager) => {
     state.manager = manager;
  },

  SET_ALL_MANAGERS: (state, allManagers) => {
      state.allManagers = allManagers;
  },

  SET_MANAGERS_WITHOUT_SCHEDULE: (state,  managersWithoutSchedule) => {
    state.managersWithoutSchedule =  managersWithoutSchedule;
  },

  SET_MANAGERS_WITH_SCHEDULE: (state,  managersWithSchedule) => {
        state.managersWithSchedule =  managersWithSchedule;
  },
};

const actions = {
  managers({commit, dispatch}, params) {
    return service.managers(params)
      .then((managers) => {
        commit('SET_MANAGERS', managers);
      });
  },

  allManagers({commit, dispatch}, params) {
      return service.allManagers(params)
        .then((managers) => {
                commit('SET_ALL_MANAGERS', managers);
       });
  },

  managersWithoutSchedule({commit, dispatch}, params) {
      return service.managersWithoutSchedule(params)
           .then((managers) => {
                commit('SET_MANAGERS_WITHOUT_SCHEDULE', managers);
      });
  },

  managersWithSchedule({commit, dispatch}, params) {
        return service.managersWithSchedule(params)
            .then((managers) => {
                commit('SET_MANAGERS_WITH_SCHEDULE', managers);
            });
  },


  get({commit, dispatch}, params) {
    return service.get(params)
      .then((user) => { commit('SET_RESOURCE', user); });
  },

  manager({commit, dispatch}, params) {
     return service.manager(params)
      .then((manager) => { commit('SET_MANAGER', manager); });
  },

  all({commit, dispatch}, params) {
        return service.all(params)
       .then((all) => { commit('SET_ALL', all); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((user) => { commit('SET_RESOURCE', user); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((user) => { commit('SET_RESOURCE', user); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  createManager({commit, dispatch}, params) {
     return service.createManager(params)
         .then((manager) => { commit('SET_MANAGER', manager);});
  },
};

const getters = {
  managers: state => state.managers,
  user: state => state.user,
  all: state => state.all,
  manager: state => state.manager,
  allManagers: state => state.allManagers,
  managersWithoutSchedule: state => state.managersWithoutSchedule,
  managersWithSchedule: state => state.managersWithSchedule,
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default users;
